<template>
  <v-app>
    <div
      class="appstyle content-style overflow-y-auto grey lighten-4"
      v-if="loaded"
    >
      <router-view />
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
    loaded: false,
  }),

  created() {
    if (!this.$store.getters.userData) {
      window.addEventListener("UserDataLoaded", () => {
        this.$store.dispatch("updateUserDataFromCookie");

        this.loaded = true;
      });
      window.dispatchEvent(new Event("getUserData"));
    } else {
      this.$store.dispatch("updateUserDataFromCookie");
      this.loaded = true;
    }
  },
};
</script>

<style lang="scss">
.appstyle {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content-style {
  height: calc(100vh);
}
</style>
