<template>
  <HomePage />
</template>

<script>
import HomePage from "../components/HomePage";

export default {
  name: "HomeView",

  components: {
    HomePage,
  },
};
</script>
