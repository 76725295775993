import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#1178FF",
        light_green: "#29C48A",
        light_grey: "#F0F0F0",
        dark_blue: "#22224F",
        yms_orange: "#FC4817",
        yms_cyan: "#1478FD",
      },
    },
  },
});
