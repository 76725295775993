import axios from "axios";
import applicationInsightsModule from "../store/applicationInsightsModule";

export default {
  /**
   * Get method.
   * @param {string} url - Endpoint of function.
   * @param {object} [params] - Params to pass.
   * @param {string} username - User calling.
   * @returns {Promise} - Promise object.
   * @throws {Error} - Error object.
   *
   */
  async get(
    url,
    params = {},
    username = "UNDEFINED",
    eventName = "GET CUSTOM REQUEST"
  ) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Ocp-Apim-Subscription-Key": process.env.VUE_APP_APIMANAGEMENT_HEADER,
      },
      url: url,
      method: "get",
      timestamp: new Date().getTime(),
      params: params,
    };

    try {
      const response = await axios(config);

      applicationInsightsModule.actions.sendAppInsightEvent(
        { commit: null },
        {
          properties: {
            eventName: eventName,
            success: true,
            function: config.url,
            payload: config.params,
            response: response,
            latency: new Date().getTime() - response.config.timestamp,
            user: username,
          },
        }
      );
      return response;
    } catch (error) {
      applicationInsightsModule.actions.sendAppInsightException(
        { commit: null },
        {
          properties: {
            error: error,
            success: false,
            function: config.url,
            payload: config.params,
            user: username,
          },
        }
      );
      throw error;
    }
  },

  /**
   * Post method.
   * @param {string} url - Endpoint of function.
   * @param {object} [data] - Data to pass.
   * @param {string} username - User calling.
   * @returns {Promise} - Promise object.
   * @throws {Error} - Error object.
   *
   */
  async post(
    url,
    data = {},
    username = "UNDEFINED",
    eventName = "POST CUSTOM REQUEST"
  ) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Ocp-Apim-Subscription-Key": process.env.VUE_APP_APIMANAGEMENT_HEADER,
      },
      url: url,
      method: "post",
      timestamp: new Date().getTime(),
      data: data,
    };

    try {
      const response = await axios(config);

      applicationInsightsModule.actions.sendAppInsightEvent(
        { commit: null },
        {
          properties: {
            eventName: eventName,
            success: true,
            function: config.url,
            payload: config.data,
            response: response,
            latency: new Date().getTime() - response.config.timestamp,
            user: username,
          },
        }
      );
      return response;
    } catch (error) {
      applicationInsightsModule.actions.sendAppInsightException(
        { commit: null },
        {
          properties: {
            error: error,
            success: false,
            function: config.url,
            payload: config.data,
            user: username,
          },
        }
      );
      throw error;
    }
  },

  /**
   * Delete method.
   * @param {string} url - Endpoint of function.
   * @param {object} [data] - Data to pass.
   * @param {string} username - User calling.
   * @returns {Promise} - Promise object.
   * @throws {Error} - Error object.
   *
   */
  async delete(
    url,
    data = {},
    username = "UNDEFINED",
    eventName = "DELETE CUSTOM REQUEST"
  ) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Ocp-Apim-Subscription-Key": process.env.VUE_APP_APIMANAGEMENT_HEADER,
      },
      url: url,
      method: "delete",
      timestamp: new Date().getTime(),
      data: data,
    };

    try {
      const response = await axios(config);

      applicationInsightsModule.actions.sendAppInsightEvent(
        { commit: null },
        {
          properties: {
            eventName: eventName,
            success: true,
            function: config.url,
            payload: config.data,
            response: response,
            latency: new Date().getTime() - response.config.timestamp,
            user: username,
          },
        }
      );
      return response;
    } catch (error) {
      applicationInsightsModule.actions.sendAppInsightException(
        { commit: null },
        {
          properties: {
            error: error,
            success: false,
            function: config.url,
            payload: config.data,
            user: username,
          },
        }
      );
      throw error;
    }
  },

  /**
   * Patch method.
   * @param {string} url - Endpoint of function.
   * @param {object} [data] - Data to pass in the request body.
   * @param {string} username - User calling.
   * @param {string} eventName - Event name for tracking.
   * @returns {Promise} - Promise object.
   * @throws {Error} - Error object.
   */
  async patch(
    url,
    data = {},
    username = "UNDEFINED",
    eventName = "PATCH CUSTOM REQUEST"
  ) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Ocp-Apim-Subscription-Key": process.env.VUE_APP_APIMANAGEMENT_HEADER,
      },
      url: url,
      method: "patch", // Use "patch" HTTP method for partial updates.
      timestamp: new Date().getTime(),
      data: data, // Include the data to be sent in the request body.
    };

    try {
      const response = await axios(config);

      applicationInsightsModule.actions.sendAppInsightEvent(
        { commit: null },
        {
          properties: {
            eventName: eventName,
            success: true,
            function: config.url,
            payload: config.data,
            response: response,
            latency: new Date().getTime() - response.config.timestamp,
            user: username,
          },
        }
      );
      return response;
    } catch (error) {
      applicationInsightsModule.actions.sendAppInsightException(
        { commit: null },
        {
          properties: {
            error: error,
            success: false,
            function: config.url,
            payload: config.data,
            user: username,
          },
        }
      );
      throw error;
    }
  },

  /**
   * Put method.
   * @param {string} url - Endpoint of function.
   * @param {object} [data] - Data to pass.
   * @param {string} username - User calling.
   * @returns {Promise} - Promise object.
   * @throws {Error} - Error object.
   *
   */
  async put(
    url,
    data = {},
    username = "UNDEFINED",
    eventName = "PUT CUSTOM REQUEST"
  ) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Ocp-Apim-Subscription-Key": process.env.VUE_APP_APIMANAGEMENT_HEADER,
      },
      url: url,
      method: "put",
      timestamp: new Date().getTime(),
      data: data,
    };

    try {
      const response = await axios(config);

      applicationInsightsModule.actions.sendAppInsightEvent(
        { commit: null },
        {
          properties: {
            eventName: eventName,
            success: true,
            function: config.url,
            payload: config.data,
            response: response,
            latency: new Date().getTime() - response.config.timestamp,
            user: username,
          },
        }
      );
      return response;
    } catch (error) {
      applicationInsightsModule.actions.sendAppInsightException(
        { commit: null },
        {
          properties: {
            error: error,
            success: false,
            function: config.url,
            payload: config.data,
            user: username,
          },
        }
      );
      throw error;
    }
  },

  /**
   * Fetch method.
   * @param {string} url - Endpoint of function.
   * @param {object} [params] - Params to pass.
   * @param {string} username - User calling.
   * @returns {Promise} - Promise object.
   * @throws {Error} - Error object.
   *
   */
  async fetch(url, username = "UNDEFINED", eventName = "FETCH CUSTOM REQUEST") {
    const config = {
      url: url,
      method: "fetch",
      timestamp: new Date().getTime(),
    };

    try {
      const response = await axios(config);

      applicationInsightsModule.actions.sendAppInsightEvent(
        { commit: null },
        {
          properties: {
            eventName: eventName,
            success: true,
            function: config.url,
            payload: config.params,
            response: response,
            latency: new Date().getTime() - response.config.timestamp,
            user: username,
          },
        }
      );
      return response;
    } catch (error) {
      applicationInsightsModule.actions.sendAppInsightException(
        { commit: null },
        {
          properties: {
            error: error,
            success: false,
            function: config.url,
            payload: config.params,
            user: username,
          },
        }
      );
      throw error;
    }
  },
};
